import {
  Image,
  Box,
  Text,
  Heading,
  Flex,
  useBreakpoint,
} from '@chakra-ui/react'
import { Hero } from '@components/Hero'
import { SectionContainer } from '@components/SectionContainer'
import { graphql } from 'gatsby'

const ReadershipPage = ({ data }) => {
  const { features, hero } = data.readershipJson
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'
  return (
    <>
      {hero && (
        <SectionContainer bgColor="rgba(0, 0, 0, 0) linear-gradient(rgb(128, 184, 246) 0%, rgb(218, 241, 232) 100%)">
          <Hero
            smallTitle={hero?.subtitle}
            largeTitle={hero?.title}
            ctaCopy={hero?.text}
            img={hero?.image.childImageSharp}
          />
        </SectionContainer>
      )}
      {features && (
        <SectionContainer bgColor="rgb(244, 242, 234)">
          <Heading textAlign="center" fontSize={40} mt={12}>
            {features.title}
          </Heading>
          <Text textAlign="center" fontSize={24}>
            {features.subtitle}
          </Text>
          <Flex my={12} direction={isMobile ? 'column' : 'row'}>
            {features.items.map(feature => (
              <Box
                key={feature.title}
                textAlign="center"
                px={isMobile ? 24 : 0}
                mb={isMobile ? 12 : 0}
                mx={isMobile ? 0 : 8}
              >
                <Image
                  src={feature.image.publicURL}
                  alt={feature.name}
                  display="inline-block"
                  h={36}
                  mb={4}
                />
                <Box
                  dangerouslySetInnerHTML={{
                    __html: feature.content.childMarkdownRemark.html,
                  }}
                />
              </Box>
            ))}
          </Flex>
        </SectionContainer>
      )}
    </>
  )
}

export default ReadershipPage

export const query = graphql`
  query ReadershipQuery {
    readershipJson {
      hero {
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 329, quality: 100) {
              srcSet
              aspectRatio
            }
          }
        }
      }
      features {
        title
        subtitle
        items {
          name
          content {
            childMarkdownRemark {
              html
            }
          }
          image {
            publicURL
          }
        }
      }
    }
  }
`
